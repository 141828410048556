<template>
    <div>
        <div v-if="noLink">
            <v-avatar :size='size' class="indigo rounded-circle avatar__image">
                <img :src="avatar" alt="avatar" v-if="avatar != ''">
                <span class="white--text headline" v-else>{{name !== '' ? name.charAt(0) :getName.charAt(0)}}</span>
            </v-avatar>
        </div>
        <router-link v-else :to="'/' + getRole + '/my-account' "  tag="div" class='cursor-pointer'>
            <v-avatar :size='size' class="indigo rounded-circle">
                <img :src="getAvatar" alt="avatar" v-if="getAvatar">
                <span class="white--text headline" v-else>{{name !== '' ? name.charAt(0) :getName.charAt(0)}}</span>
            </v-avatar>
        </router-link>
    </div>
</template>


<script>
export default {
    props: {
        size: {
            type: String,
            default: '100',
        },
        name: {
            type: String,
            default: ''
        },
        avatar: {
            type: String,
            default: ''
        },
        noLink: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        getAvatar(){
            return  this.$store.getters.getUserAvatar
        },
        getName(){
            return this.$store.getters.getUserName
        },
        getRole(){
            return this.$store.getters.getUserRole
        }
    },
}
</script>

<style lang="scss" scoped>
        .cursor-pointer{
            cursor: pointer;
        }
        .avatar{
            &__image{
                img{
                    object-fit: cover;
                    object-position: center;
                }        
            }
        }
</style>