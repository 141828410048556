<template>
    <div class="task">
        <v-container class="my-5 px-10">
          <h1 v-if="role === 'administrator' || role === 'moderator' || role === 'korektor'">{{name}}</h1>
            <v-layout row class="my-4">
            <v-flex md2 class="mx-2" >
                <div class="d-flex align-center mb-4"  v-if="role === 'praktykant' && status === 'Przyjęte'">
                  <v-btn
                    color="green"
                    class="mr-2 white--text"
                    @click.stop="done()"
                  >
                    Tekst gotowy
                  </v-btn>
                </div>
                <div class="d-flex align-center"  v-if="(role === 'korektor') && status === 'Do sprawdzenia'">
                  <v-btn
                    color="green"
                    class="mr-2 mb-4 white--text"
                    @click.stop="end()"
                  >
                    Zadanie zakończone
                  </v-btn>
                </div>
                <v-layout>
                <v-card min-height="50" class="mr-5 px-5">
                  <v-list-item two-line >
                    <v-list-item-content>
                        <v-list-item-title class="headline mb-1">{{signs}}</v-list-item-title>
                        <v-list-item-subtitle>Znaki</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
                <v-card min-height="50" v-if="role === 'administrator' || role === 'moderator'">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-select
                        :items="allStatus"
                        v-model="status"
                        @change="changedStatus"
                      ></v-select>
                      <v-list-item-subtitle>Status</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
                </v-layout>
            </v-flex>
            </v-layout>
            <v-layout row class="my-4" >
                <v-flex md8 >
                  <v-card height="600" class="scroll mr-10 py-6 px-6 text-left">
                      <h2 class="text-left mb-4">{{title}}</h2>
                      <div v-html="description"></div>
                  </v-card>

                </v-flex>

              <v-flex md4>
                <v-card  height="600" class="scroll pa-3">
                  <ApprenticeAttachmets :taksId="this.id" :status="this.status" />
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex >
                  <v-card class="pa-4">
                    <ApprenticeComments />
                  </v-card>
              </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import io from 'socket.io-client';
import ApprenticeAttachmets from '@/components/organisms/ApprenticeAttachmets.vue';
import ApprenticeComments from '@/components/organisms/ApprenticeComments.vue';

const token = localStorage.getItem('user');

export default{
    name: "TaskApprentice",
    components: {
        ApprenticeAttachmets,
        ApprenticeComments
    },
    computed: {
        role(){
          return this.$store.getters.getUserRole;
        },
        userID(){
          return this.$store.getters.getUserID;
      },
    },
    data(){
      return{
			isLoaded: false,
			id: this.$route.params.id,
			taskId: this.$route.params.idtask,
      description: null,
      title: null,
      correctorId: null,
      socket: io(this.url),
      text: null,
      modalToCheck: false,
      signs: null,
      name: null,
      status: 'Przyjęte',
      feedback: null,
      tasks: [],
      menu: false,
      allStatus: [
          { text: 'Przyjęte' },
          { text: 'Do sprawdzenia' },
          { text: 'Zakończone' },
			],
        }
    },
    methods: {
        done(){
            this.$axios.put(`${this.url}api/apprentice/task-status`, {
            status: 'Do sprawdzenia',
            apprenticeId: this.$route.params.id,
            taskid: this.$route.params.idtask,
            date: new Date().toISOString(),
            correctorMail: this.correctorId
          }, { headers: { 'x-access-token': token } })
        },
        end(){
          this.$axios.put(`${this.url}api/apprentice/task-status`, {
            status: 'Zakończone',
            apprenticeId: this.$route.params.id,
            taskid: this.$route.params.idtask,
            date: new Date().toISOString(),
            correctorMail: this.correctorId
          }, { headers: { 'x-access-token': token } })
        },
        changedStatus(item){
          this.$axios.put(`${this.url}api/apprentice/task-status`, {
            status: item,
            apprenticeId: this.$route.params.id,
            taskid: this.$route.params.idtask,
            date: new Date().toISOString(),
            correctorMail: this.correctorId
          }, { headers: { 'x-access-token': token } })
        },
        getApprentice(){
          this.$axios.get(`${this.url}api/apprentice/${this.id}`, { headers: { 'x-access-token': token } })
          .then(el => {
            this.correctorId = el.data.correctorId;
            this.tasks = el.data.tasks;
            this.name =  el.data.name;
            this.tasks.forEach(element => {
              if( element._id == this.taskId){
                this.title = element.title;
                this.description = element.desciption;
                this.status = element.status;
                this.signs = element.characters ? element.characters : 3000;
              }
            });
          })
        }
    },
    created() {
        this.getApprentice()

        this.socket.on('newTaskAprrenticeEdited', () => {
          this.getApprentice()
        })
        this.socket.on('appreticeChangedStatus', () => {
          this.getApprentice()
        })
    },

}
</script>

<style lang="scss">
    .cursor-pointer{
        cursor: pointer;
    }
    .align-center-element{
        align-self: center;
    }
    .scroll {
        overflow-y: auto;
    }
</style>
